<script setup lang="ts">
import type { User } from "~/types/user"

const user = useStrapiUser<User>();
const { logout } = useStrapiAuth()

function isLoggedIn() {
  return !!user.value
}

function onSignOut() {
    logout()
    navigateTo('/login')
}
</script>

<template>
  <div class="flex justify-between items-center p-4 
  bg-white-10
  sticky top-0 z-10 w-full shadow-md">
    <NuxtLink to="/" class="text-black flex items-center">
      <svg viewBox="0 0 117.724 117.722" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <g id="SvgjsG1012" featurekey="HKaMnE-0" transform="matrix(1.428547978401184, 0, 0, 1.428547978401184, -10.420923388316083, -7.202758896391963)" fill="#98B292">
          <g xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, -1.358372, -3.375095)">
            <path d="M86.834,90.834H33.166c-2.209,0-4-1.791-4-4V61.5c0-2.209,1.791-4,4-4h33.668c2.209,0,4,1.791,4,4v10c0,2.209-1.791,4-4,4   s-4-1.791-4-4v-6H37.166v17.334h45.668V56.69L49.976,19.206l-32.81,36.819v30.809c0,2.209-1.791,4-4,4s-4-1.791-4-4V54.502   c0-0.981,0.361-1.929,1.014-2.661l36.834-41.336c0.763-0.856,1.816-1.368,3.002-1.339c1.147,0.005,2.236,0.501,2.992,1.363   l36.834,42.02c0.64,0.729,0.992,1.667,0.992,2.637v31.648C90.834,89.043,89.043,90.834,86.834,90.834z"/>
          </g>
        </g>
      </svg>
      <span class="hidden sm:inline text-lg ml-2 " style="font-family: 'Radio Canada Big';color:#98B292;font-weight:500;">Boligkartoteket</span>
      <!-- <PhHouse size="24" class="inline-block mr-2" /> <span class="hidden sm:inline text-lg">Boligkartoteket</span> -->
    </NuxtLink>
    
    <nav>
      <ul v-if="isLoggedIn()" class="flex text-sm text-black">
        <li class="py-1 px-2 hover:underline border-r border-blue-30">
          <NuxtLink to="/administration/dashboard">
            <PhClipboard size="20" class="inline-block mr-2" /> Mine boliger
          </NuxtLink>
        </li>
        <li class="py-1 px-2 hover:underline cursor-pointer" @click="onSignOut">
          <PhSignOut size="20" class="inline-block mr-2" /> Log ud
        </li>
      </ul>
      <ul v-else class="flex text-sm text-white">
        <li class="py-1 px-2 hover:underline">
          <NuxtLink to="/login">
            <PhSignIn size="20" color="black" class="inline" />
            Log ind
          </NuxtLink>
        </li>
        <li class="py-1 px-2 hover:underline">
          <NuxtLink to="/signup">
            <PhUserPlus size="20" color="black" class="inline" />
            Opret
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

